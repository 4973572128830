/*----------------------*/
/*  Tab Items
/*----------------------*/

/* Tab Navigation Container */
.Tab-Items {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  padding: 2rem 8rem;
}

.Tab-Items li {
  list-style: none;
  cursor: pointer;
  padding: 0.5rem;
  font-size: 18px;
}

.Tab-Items li:hover {
  border-bottom: 3px solid #0c6cde;
}

.Tab-Items li.active {
  border-bottom: 3px solid #0c6cde;
}

/*----------------------*/
/*  Tab Content
/*----------------------*/

/* Tab Content Container */
.Tab-Content {
  min-height: 550px;
  background: #f8fcff;
  margin: 1rem 8rem 5rem 8rem;
  padding: 5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* Community & Secuirty Tab Container */
.Content-Container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

/* Left side of Grid -> Image */
.Content-Left {
  grid-column: 1;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Content-Left img {
  width: 90%;
  height: 100%;
  object-fit: contain;
}

/* Right side of Grid -> Text & Button */
.Content-Right {
  grid-column: 2;
  display: grid;
  place-content: center;
  margin-left: 4rem;
}

.Content-Right > p {
  font-size: 16px;
  padding-bottom: 1.3rem;
}

a {
  text-decoration: none;
}
