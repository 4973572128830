* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  src: url(../src/Fonts/CircularStd-Book_1.ttf);
  font-family: Circular-body;
}

@font-face {
  src: url(../src/Fonts/CircularStd-Bold_0.ttf);
  font-family: Circular-bold;
}

@font-face {
  src: url(../src/Fonts/CircularStd-Medium.ttf);
  font-family: Circular-subheader;
}

@font-face {
  src: url(../src/Fonts/CircularXX-Light.otf);
  font-family: Circular-light;
}

:root {
  --body: Circular-body;
  --subheader: Circular-subheader;
  --bold: Circular-bold;
  --light: Circular-light;
  --button-action-colour: #0c6cde;
  --button-action-colour-hover: #00499f;
  --button-cancel-colour: #0c6cde;
  --button-cancel-colour-hover: #00499f;
  --button-font: Circular-body;
}

html {
  background-color: #ffffff;
  background: linear-gradient(145deg, #ffffff, #ffffff);
  background-repeat: no-repeat;
  background-size: cover;
  color: rgb(0, 0, 0);
}

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  font-family: Circular-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 36px;
}

h4 {
  font-size: 20px;
}

p {
  font-size: 16px;
  line-height: 1.7;
}

/* Header Styling */
header {
  display: flex;
  align-items: center;
  height: 90px;
  background-color: #282e36;
  padding-left: 8rem;
}

/* Footer Styling */
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background-color: #faf9f9;
}

/*--------------------------------------------*/
/*  Common Styling for feedback pages
/*--------------------------------------------*/

/* Feedback Container */
.Feedback-Body-Container {
  min-height: calc(100vh - 170px);
  background-color: #e2f0fe;
  padding: 0 8rem;
}

/* Pages with 1 Column */
.Feedback-Centre {
  min-height: calc(100vh - 170px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.Feedback-Centre p {
  font-size: 18px;
}

/* Pages with Grid -> 2 Columns (Left & Right) */
.Feedback-Grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.Grid-Left {
  grid-column: 1;
  display: grid;
  place-content: center;
}

.Grid-Right {
  grid-column: 2;
  display: grid;
  place-content: center;
}

.Grid-Image {
  width: 90%;
  margin: 0 auto;
  object-fit: contain;
}

.Feedback-Grid p {
  font-family: var(--light);
  padding: 1rem 0;
  font-size: 16px;
}

.Text-Container {
  padding: 0 3rem;
}

/* Styling for Next Steps Page  */
.Steps-Container {
  display: flex;
  align-items: center;
  font-size: 16px;
}

/* Blue Dots -> Next Steps Page */
.Blue-Dot {
  height: 50px;
  width: 50px;
  background: #18b4ea;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px;
}
