/*----------------------*/
/*  Buttons
/*----------------------*/

/* Primary -> Blue Background */
.Button-Primary {
  background-color: var(--button-action-colour);
  border-radius: 6px;
  border: none;
  min-width: 80px;
  width: max-content;
  color: #fff;
  padding: 14px 20px;
  cursor: pointer;
  font-size: var(--fontsize-body);
  font-family: var(--body, Circular-body);
}

/*  Secondary -> Transparent and Blue Border */
.Button-Secondary {
  border-radius: 6px;
  background-color: transparent;
  border: none;
  min-width: 80px;
  width: max-content;
  color: var(--button-action-colour);
  padding: 12px 16px;
  cursor: pointer;
  font-size: var(--fontsize-body);
  font-family: var(--body, Circular-body);
}

.Button-Secondary:hover {
  border: 2px solid var(--button-action-colour);
  border-radius: 6px;
}

/*----------------------*/
/*  Hero
/*----------------------*/

/* Hero Container -> Grid 2 equal columns */
.Hero {
  height: 300px;
  background-color: #e2f0fe;
  display: grid;
  grid-template-columns: repeat(2, 60%, 40%);
}

/* Left Side of Hero Container -> Text, Button, Input */
.Info-Container {
  grid-column: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  padding: 0 8rem;
}

/* Right Side Container -> Google Icon */
.Icon-Container {
  grid-column: 2;
  display: flex;
  flex-direction: row-reverse;
}

/* Google Icon */
.Google-Icon {
  object-fit: contain;
  height: 300px;
}

/*----------------------*/
/*  Why Google Section
/*----------------------*/

/* Why Google Cloud Section -> Underneath Hero */
.Information-Section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  background-color: #faf9f9;
  padding: 2rem 8rem;
  font-family: var(--light);
}

/*----------------------*/
/*  Input Field & Label
/*----------------------*/

/* Input styling */
.Input {
  font-size: var(--fontsize-body);
  font-family: var(--body, Circular-body);
  width: 200px;
  margin-right: 1rem;
  padding: 0.8rem 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  color: rgb(121, 121, 121);
}

.Input-Invalid {
  border: 1px solid red !important;
}

/* Error Label Styling */
.Error-Label {
  font-size: var(--fontsize-body);
  font-family: var(--body, Circular-body);
  color: red;
}

.Hide-Label {
  display: none;
}
