/* Container */
.Carousel-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.Carousel-Container > p {
  font-size: 18px;
}

/* Image */
.Carousel-Img {
  width: 800px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* Dots */
.Dot-Container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}

.Dot {
  cursor: pointer;
  height: 16px;
  width: 16px;
  background-color: rgba(208, 208, 208, 0.71);
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.Dot:hover {
  background-color: #18b4ea;
}

.Dot-Active {
  background-color: #18b4ea;
}
